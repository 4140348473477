import { NON_EURO_COUNTRIES } from "./constants";
import {
  AccountType,
  Plan,
  PlanInfo,
  PlanInfoOrg,
  Subscription,
  TPlanCode,
} from "models/subscription";
import { SegmentEventType } from "hooks/analyticsHook";
import { ReqProps } from "models/reqProps";
import { Coupon } from "models/coupon";

export type TCurrency = "€" | "$" | "CA$" | "A$" | "£" | "R$";
export type TCurrencyKey = "EUR" | "USD" | "GBP" | "AUD" | "CAD" | "BRL";

export interface ICurrencyCodeResponse {
  currency: TCurrency;
  currencyKey: TCurrencyKey;
}

export const getCurrencyRegion = (
  countryCode: string | undefined,
  continentCode: string | undefined
): TCurrencyKey => {
  if (!!countryCode) {
    if (countryCode === "GB") {
      return "GBP";
    }
    if (continentCode == "EU" && !NON_EURO_COUNTRIES.includes(countryCode)) {
      return "EUR";
    }
    if (countryCode === "CA") {
      return "CAD";
    }
    if (countryCode === "AU") {
      return "AUD";
    }
    if (countryCode === "BR") {
      return "BRL";
    }
  }
  return "USD";
};

export const getDefaultMonthlyPlanCode = (
  countryCode: string | undefined,
  continentCode: string | undefined
): TPlanCode => {
  const currencyRegion = getCurrencyRegion(countryCode, continentCode);
  switch (currencyRegion) {
    case "GBP":
      return "individual-monthly-gbp";
    case "EUR":
      return "individual-monthly-eur";
    case "AUD":
      return "individual-monthly-aud";
    case "CAD":
      return "individual-monthly-cad";
    case "BRL":
      return "individual-monthly-brl";
    default:
      return "individual-monthly-usd";
  }
};

export const getDefaultYearlyPlanCode = (
  countryCode: string | undefined,
  continentCode: string | undefined
): TPlanCode => {
  const currencyRegion = getCurrencyRegion(countryCode, continentCode);
  switch (currencyRegion) {
    case "GBP":
      return "individual-yearly-gbp";
    case "EUR":
      return "individual-yearly-eur";
    case "AUD":
      return "individual-yearly-aud";
    case "BRL":
      return "individual-yearly-brl";
    case "CAD":
      return "individual-yearly-cad";
    default:
      return "individual-yearly-usd";
  }
};

export const isMonthlyCode = (planCode: TPlanCode): boolean => {
  return [
    "individual-monthly-aud",
    "individual-monthly-eur",
    "individual-monthly-gbp",
    "individual-monthly-cad",
    "individual-monthly-brl",
    "individual-monthly-usd",
  ].includes(planCode);
};

export const isYearlyPlanCode = (planCode: TPlanCode): boolean => {
  return [
    "individual-yearly-aud",
    "individual-yearly-eur",
    "individual-yearly-gbp",
    "individual-yearly-cad",
    "individual-yearly-brl",
    "individual-yearly-usd",
  ].includes(planCode);
};

export const currencyByCode = (
  currencyCode: TCurrencyKey
): ICurrencyCodeResponse => {
  switch (currencyCode) {
    case "GBP":
      return {
        currency: "£",
        currencyKey: "GBP",
      };
    case "EUR":
      return {
        currency: "€",
        currencyKey: "EUR",
      };
    case "AUD":
      return {
        currency: "A$",
        currencyKey: "AUD",
      };
    case "CAD":
      return {
        currency: "CA$",
        currencyKey: "CAD",
      };
    case "BRL":
      return {
        currency: "R$",
        currencyKey: "BRL",
      };
    default:
      return {
        currency: "$",
        currencyKey: "USD",
      };
  }
};

export const getCurrencyCodes = (
  countryCode: string | undefined,
  continentCode: string | undefined
): ICurrencyCodeResponse => {
  const currencyRegion = getCurrencyRegion(countryCode, continentCode);
  return currencyByCode(currencyRegion);
};

export const calculatePlanTrialExpirationDate = (
  plan: Plan,
  hasReferral?: boolean,
  coupon?: Coupon | null
): number | Date => {
  let { trialIntervalLength, trialIntervalUnit } = plan;
  const today = new Date();
  if (hasReferral) return today.setDate(today.getDate() + 2 * 7);
  if (coupon && coupon.trialInterval && coupon.trialIntervalCount) {
    trialIntervalUnit = coupon.trialInterval + "s";
    trialIntervalLength = coupon.trialIntervalCount;
  }

  if (trialIntervalUnit === "days")
    return today.setDate(today.getDate() + trialIntervalLength);

  if (trialIntervalUnit === "weeks")
    return today.setDate(today.getDate() + trialIntervalLength * 7);

  if (trialIntervalUnit === "months")
    return today.setMonth(today.getMonth() + trialIntervalLength);

  return today;
};

export const fmtTrialExpirationDate = (
  plan?: Plan,
  hasReferral?: boolean,
  coupon?: Coupon | null
): string => {
  if (!plan) return "";
  const expirationDate = calculatePlanTrialExpirationDate(
    plan,
    hasReferral,
    coupon
  );
  const trialExpiration = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(expirationDate);
  return trialExpiration;
};

export const fmtSubscriptionBillingDate = (
  subscription?: Subscription
): string => {
  if (!subscription) return "";
  const billingDate = new Date(subscription.currentPeriodEndsAt);
  const trialExpiration = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(billingDate);
  return trialExpiration;
};

export const trackAfterSubscribe = (
  accountType: AccountType,
  track: (event: SegmentEventType, properties: any) => void,
  subscriptionExpired: boolean,
  paymentToken?: string,
  plan?: Plan,
  zipCode?: string | null,
  signupVersion?: "variant" | "baseline"
): void => {
  if (!plan) return;
  const { currency, amountInCents } = plan;
  const amount = (amountInCents[currency?.toUpperCase() || "USD"] || 0) / 100;

  const trackData = {
    signupVersion,
    accountType,
    orderId: paymentToken,
    platform: "Web",
    affiliation: "Web",
    subtotal: amount,
    total: amount,
    revenue: amount,
    shipping: 0,
    currency: plan.currency,
    zipCode: zipCode ?? "",
    products: {
      id: plan.id,
      name: plan.name,
      price: amount,
      quantity: 1,
    },
  };
  if (!subscriptionExpired) {
    track("Subscribed", trackData);
    track("Order Completed", trackData);
  }
};

export const trackSubscrbeCta = (
  track: (event: SegmentEventType, properties: any) => void,
  step: "ath-payment" | "org-payment",
  expired: boolean
): void => {
  track("CTA Tapped", {
    name:
      step === "ath-payment" && expired
        ? "Start Your Membership"
        : step === "ath-payment"
        ? "Start Your Trial"
        : "Start Your Membership Organization",
    source: "Payment Page",
  });
};

export const orgPlanPrice = (billingCount: number, plan: Plan): string => {
  let totalCostInCents = 0;
  let remainingCount = billingCount;
  if (!plan || !plan.tiers) return "$0.00";

  for (const tier of plan.tiers) {
    if (remainingCount <= 0) break;

    const tierLimit = tier.upTo ?? Infinity;
    const tierStart =
      plan?.tiers.indexOf(tier) > 0
        ? plan.tiers[plan.tiers.indexOf(tier) - 1].upTo
        : 0;
    const unitsInTier = Math.min(remainingCount, tierLimit - (tierStart || 0));

    if (tier.flatAmount) {
      totalCostInCents = Math.max(totalCostInCents, tier.flatAmount);
      if (tierLimit >= billingCount) {
        break;
      }
    }

    if (tier.unitAmount) {
      totalCostInCents += unitsInTier * tier.unitAmount;
    }

    remainingCount -= unitsInTier;
  }

  return (totalCostInCents / 100).toFixed(2);
};

export const monthlyPlanInfoOrg = (plans: Plan[] | null): PlanInfoOrg => {
  const monthlyPlan = Array.isArray(plans)
    ? (plans?.find((plan) => plan.code === "org-account-plan") as Plan)
    : (plans as unknown as Plan);

  const monthlyPlanCurrencyCode = monthlyPlan?.currency?.toUpperCase();

  const monthlyPlanCurrency = currencyByCode(
    monthlyPlanCurrencyCode as TCurrencyKey
  );

  const monthlyPlanAmt =
    (monthlyPlanCurrencyCode &&
      monthlyPlan?.amountInCents[monthlyPlanCurrencyCode] / 100) ||
    0;

  const monthly: PlanInfoOrg = {
    type: "monthly",
    currency: monthlyPlanCurrency.currency,
    currencyCode: monthlyPlanCurrency.currencyKey,
    plan: monthlyPlan,
    monthlyAmount: monthlyPlanAmt,
    yearlyAmount: monthlyPlanAmt * 12,
    monthlyAmountStr: `${monthlyPlanCurrency.currency}${monthlyPlanAmt.toFixed(
      2
    )}`,
    yearlyAmountStr: `${monthlyPlanCurrency.currency}${(
      monthlyPlanAmt * 12
    ).toFixed(2)}`,
  };
  return monthly;
};

export const yearlyPlanInfoOrg = (plans: Plan[] | null): PlanInfoOrg => {
  const yearlyPlan =
    plans && Array.isArray(plans)
      ? (plans?.find((plan) => plan.code === "org-account-yearly") as Plan)
      : (plans as unknown as Plan);

  const yearlyPlanCurrencyCode = yearlyPlan?.currency?.toUpperCase();
  const yearlyPlanCurrency = currencyByCode(
    yearlyPlanCurrencyCode as TCurrencyKey
  );
  const yearlyPlanAmt =
    (yearlyPlanCurrencyCode &&
      yearlyPlan?.amountInCents[yearlyPlanCurrencyCode] / 100) ||
    0;

  const yearlyPerMonth: number = yearlyPlanAmt
    ? yearlyPlanAmt
      ? yearlyPlanAmt / 12
      : 0
    : 1;

  const yearly: PlanInfoOrg = {
    type: "yearly",
    currency: yearlyPlanCurrency.currency,
    currencyCode: yearlyPlanCurrency.currencyKey,
    plan: yearlyPlan,
    monthlyAmount: yearlyPerMonth,
    yearlyAmount: yearlyPlanAmt,
    monthlyAmountStr: `${yearlyPlanCurrency.currency}${twoFixed(
      yearlyPerMonth
    )}`,
    yearlyAmountStr: `${yearlyPlanCurrency.currency}${yearlyPlanAmt}`,
  };
  return yearly;
};

export const calculateTieredPricing = (
  plan: Plan,
  additionalSeats,
  baseTier,
  orgSizeLimit
) => {
  let remainingSeats = additionalSeats;
  const tieredPricing: {
    seatsInThisTier: number;
    price: number;
    perUserPrice: number;
    tierLabel: string;
  }[] = [];

  let previousUpTo = baseTier?.upTo || 0;

  for (const tier of plan?.tiers) {
    if (tier.unitAmount) {
      const minSeats = previousUpTo + 1;
      const maxSeats = tier.upTo ?? orgSizeLimit;
      const seatsInThisTier = Math.min(remainingSeats, maxSeats - minSeats + 1);

      if (seatsInThisTier > 0) {
        tieredPricing.push({
          seatsInThisTier,
          price: seatsInThisTier * (tier.unitAmount / 100),
          perUserPrice: tier.unitAmount / 100,
          tierLabel: `${minSeats}-${maxSeats} Member Tier`,
        });
        remainingSeats -= seatsInThisTier;
      }

      previousUpTo = maxSeats;

      if (remainingSeats <= 0) break;
    }
  }

  return tieredPricing;
};

const twoFixed = (val: number, decimals = 2): string => {
  const num = val.toString();
  const parts = num.split(".");
  if (parts.length === 1) return val.toFixed(decimals);
  return parts[0] + "." + parts[1].substring(0, decimals);
};

export const calculateAnnualSavings = (teamSize, monthlyPlan, annualPlan) => {
  const monthlyPrice = orgPlanPrice(teamSize, monthlyPlan);

  if (!monthlyPrice) return 0;

  const monthlyCost = (monthlyPrice as unknown as number) * 12;
  const annualCost = orgPlanPrice(teamSize, annualPlan) as unknown as number;
  return monthlyCost - annualCost;
};

export const formatOrgPlanPrice = (price) => {
  return Number(price)?.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const monthlyPlanInfo = (
  currentPlan: Plan | null,
  plans: Plan[] | null,
  reqProps: ReqProps
): PlanInfo => {
  const currencyCode =
    currentPlan?.currency?.toUpperCase() ||
    getCurrencyRegion(reqProps.country, reqProps.continent);

  const individualMonthlyPlan: TPlanCode = getDefaultMonthlyPlanCode(
    reqProps.country,
    reqProps.continent
  );

  let monthlyPlan: Plan | null = Array.isArray(plans)
    ? plans.find(
        (plan) =>
          plan.code === individualMonthlyPlan &&
          plan.currency?.toUpperCase() === currencyCode
      ) || null
    : null;

  if (!monthlyPlan && currentPlan) {
    monthlyPlan =
      currentPlan.planIntervalUnit === "month"
        ? currentPlan
        : plans?.find(
            (plan) =>
              plan.planIntervalUnit === "month" &&
              plan.currency?.toUpperCase() === currencyCode
          ) || null;
  }

  if (!monthlyPlan) {
    monthlyPlan = plans?.find(
      (plan) =>
        plan.code === individualMonthlyPlan &&
        plan.currency?.toUpperCase() === currencyCode
    ) as Plan;
  }

  const monthlyPlanCurrency = currencyByCode(currencyCode as TCurrencyKey);

  const monthlyPlanAmt =
    (monthlyPlan?.amountInCents?.[currencyCode] || 0) / 100;

  const monthly: PlanInfo = {
    type: "monthly",
    currency: monthlyPlanCurrency.currency,
    currencyCode: monthlyPlanCurrency.currencyKey,
    plan: monthlyPlan,
    monthlyAmount: monthlyPlanAmt,
    yearlyAmount: monthlyPlanAmt * 12,
    monthlyAmountStr: `${monthlyPlanCurrency.currency}${monthlyPlanAmt.toFixed(
      2
    )}`,
    yearlyAmountStr: `${monthlyPlanCurrency.currency}${(
      monthlyPlanAmt * 12
    ).toFixed(2)}`,
    yearlyDiscount: "",
    promotionalPrice: monthlyPlanAmt,
    promotionalPriceStr: `$${monthlyPlanAmt.toFixed(2)}`,
    monthlyPromotionalPrice: monthlyPlanAmt,
    monthlyPromotionalPriceStr: `$${monthlyPlanAmt.toFixed(2)}`,
    coupon: null,
  };
  return monthly;
};

export const yearlyPlanInfo = (
  currentPlan: Plan | null,
  plans: Plan[] | null,
  reqProps: ReqProps,
  monthlyPlanAmt: number,
  isValidCoupon: boolean,
  couponAttrs: Coupon | null
): PlanInfo => {
  const currencyCode =
    currentPlan?.currency?.toUpperCase() ||
    getCurrencyRegion(reqProps.country, reqProps.continent);

  const individualYearlyPlan: TPlanCode = getDefaultYearlyPlanCode(
    reqProps.country,
    reqProps.continent
  );

  let yearlyPlan: Plan | null = Array.isArray(plans)
    ? plans.find(
        (plan) =>
          plan.code === individualYearlyPlan &&
          plan.currency?.toUpperCase() === currencyCode
      ) || null
    : null;

  if (!yearlyPlan && currentPlan) {
    yearlyPlan =
      currentPlan.planIntervalUnit === "year"
        ? currentPlan
        : plans?.find(
            (plan) =>
              plan.planIntervalUnit === "year" &&
              plan.currency?.toUpperCase() === currencyCode
          ) || null;
  }

  if (!yearlyPlan) {
    yearlyPlan = plans?.find(
      (plan) =>
        plan.code === individualYearlyPlan &&
        plan.currency?.toUpperCase() === currencyCode
    ) as Plan;
  }

  const yearlyPlanCurrency = currencyByCode(currencyCode as TCurrencyKey);

  const yearlyPlanAmt = (yearlyPlan?.amountInCents?.[currencyCode] || 0) / 100;

  const yearlyPromotionalPrice =
    isValidCoupon && couponAttrs?.planType === "yearly"
      ? couponAttrs?.amountOff
        ? Math.ceil(yearlyPlanAmt - couponAttrs.amountOff / 100)
        : couponAttrs?.percentOff
        ? Math.ceil(
            yearlyPlanAmt - yearlyPlanAmt * (couponAttrs.percentOff / 100)
          )
        : yearlyPlanAmt
      : yearlyPlanAmt;

  const yearlyPromotionalPerMonth = yearlyPromotionalPrice / 12;

  const yearlyDiscount =
    monthlyPlanAmt && yearlyPromotionalPerMonth
      ? (
          ((monthlyPlanAmt - yearlyPromotionalPerMonth) * 100) /
          monthlyPlanAmt
        ).toFixed(0)
      : "";

  const yearly: PlanInfo = {
    promotionalPrice: yearlyPromotionalPrice,
    promotionalPriceStr: `${
      yearlyPlanCurrency.currency
    }${yearlyPromotionalPrice.toFixed(2)}`,
    monthlyPromotionalPrice: yearlyPromotionalPerMonth,
    monthlyPromotionalPriceStr: `${yearlyPlanCurrency.currency}${twoFixed(
      yearlyPromotionalPerMonth
    )}`,
    type: "yearly",
    currency: yearlyPlanCurrency.currency,
    currencyCode: yearlyPlanCurrency.currencyKey,
    plan: yearlyPlan,
    monthlyAmount: yearlyPlanAmt / 12,
    yearlyAmount: yearlyPlanAmt,
    monthlyAmountStr: `${yearlyPlanCurrency.currency}${twoFixed(
      yearlyPlanAmt / 12
    )}`,
    yearlyAmountStr: `${yearlyPlanCurrency.currency}${yearlyPlanAmt.toFixed(
      2
    )}`,
    yearlyDiscount: yearlyDiscount,
    coupon: null,
  };
  return yearly;
};
